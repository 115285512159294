<template>
  <div class="about" :style="{paddingBottom:barBottom+ 'px'}">
  <!-- <div class="about"> -->
    <!-- 报表页面 -->
    <!-- <BSView :config="configOne" @pullingDown="pullingDownFunOne()" :isDisplay='false'> -->
      <!-- <div> -->
    <div class="content">
      <div class="contentTitle" style="color:#999999">最近使用</div>
      <ul>
        <li v-if="recentlyUsed.length == 0"></li>
        <li v-for="(item,index) in recentlyUsed" :key="index" @click="into(item)">
          <img :src="item.ico_url !=undefined?BASE_URL+item.ico_url:''" alt="">
          <div>{{item.name}}</div>
        </li>
      </ul>
    </div>
    <div class="content" v-for="(item,index) in analyseList" :key="index">
      <div class="contentTitle">{{index}}</div>
      <ul>
        <li v-for="(items,indexs) in item" :key="indexs" @click="into(items)">
          <img :src="items.ico_url !=undefined?BASE_URL+items.ico_url:''" alt="">
          <div>{{items.name}}</div>
        </li>
      </ul>
    </div>
    <!-- <div class="content">
      <div class="contentTitle">测试</div>
      <ul>
        <li v-for="(item,index) in marketing" :key="index" @click="goTo(item.intoUrl)">
          <img :src="item.imgUrl" alt="">
          <div>{{item.name}}</div>
        </li>
      </ul>
    </div> -->
    <!-- <div class="content">
      <div class="contentTitle">运营</div>
      <ul>
        <li v-for="(item,index) in operation" :key="index" @click="into(item.intoUrl)">
          <img :src="item.imgUrl" alt="">
          <div>{{item.name}}</div>
        </li>
      </ul>
    </div> -->


    <!-- </div> -->
    <!-- </BSView> -->
    
  </div>

</template>

<script>
// import BSView from "@/components/BSView.vue";
import md5 from 'js-md5'

export default {
  name: 'about',
  components: {
    // BSView
  },
  data () {
    return {
      configOne: {
        //滚动条
        id: "BSViewOne",
        bs: "",
        style: {
          width:'100%',
          bottom: 0,
          top: 0,
        },
        BSConfig:{
          pullDownRefresh:{
            threshold: 0,
            stop: 0
          }
        },
        disabledDown: false,
        disabledUp: false,
      },
      barBottom:'',//底部的高度
      recentlyUsed:[//最近使用
        {
          imgUrl:require('../../public/image/YJIcon/1.png'),
          name:'营销综合',
          intoUrl:'http://www.baidu.com'
        },
        {
          imgUrl:require('../../public/image/YJIcon/8.png'),
          name:'动态货值',
          intoUrl:'http://www.baidu.com'
        },
        {
          imgUrl:require('../../public/image/YJIcon/1.png'),
          name:'经营看板',
          intoUrl:'http://www.baidu.com'
        },
      ],
      manage:[//经营
        {
          imgUrl:require('../../public/image/YJIcon/1.png'),
          name:'经营看板',
          intoUrl:'http://www.baidu.com'
        },
        {
          imgUrl:require('../../public/image/YJIcon/2.png'),
          name:'资金日报',
          intoUrl:'http://www.baidu.com'
        },
      ],
      marketing:[//营销
        {
          id:1,
          imgUrl:require('../../public/image/YJIcon/3.png'),
          name:'报表1',
          intoUrl:'/StatementOne',
          type:2
        },
        {
          id:2,
          imgUrl:require('../../public/image/YJIcon/1.png'),
          name:'报表2',
          intoUrl:'/StatementTwo',
          type:3
        },
      ],
      operation:[//运营
        {
          imgUrl:require('../../public/image/YJIcon/8.png'),
          name:'动态货值',
          intoUrl:'http://www.baidu.com'
        },
      ],
      analyseList:[],
      BASE_URL:''
    }
  },
  watch: {
    '$route':'fetchData'
  },
  methods: {
    fetchData() {
      if(this.$route.path == '/') {
        document.title = '数加加'
        this.getRecentlyUsed()
        if(!$core.getLocal('HomeId')) {
          let now = new Date();
          let nowTime = now.getTime();//获取当前时间时间戳
          $core.setLocal('HomeId',this.CalcuMD5(String(nowTime)))
          if($core.getLocal('value') == '报表') {
            this.getUserActive('进入报表','进入报表')
          }else {
            if($core.getUrlKey("isArticle")) {
              this.getUserActive('进入主页','消息进入主页')
            }else {
              this.getUserActive('进入主页','应用进入主页')
            }
          }
        }
      }
    },
    pullingDownFunOne() {//下拉刷新
      this.configOne.disabledDown = true;
      // setTimeout(() => {
      //   this.configOne.disabledDown = false;
      // }, 1000);
    },
    into(item) {
      $core.clearLocal('HomeId')
      $core.request('front/analyse/open_report?id='+item.id, res => {
        // console.log(res)
      },{
        // id:item.id,
        // token:'82ec79161c60e4d6262d1fc2647c09e6'
      },"GET");
      if(item.type == 1) {
        window.location.href = item.url
      }
      if(item.type == 2) {
        // window.location.href = item.url
        this.$router.push({
          path: '/StatementOne', //跳转的路径
          query: {
            id:item.id
          },
        });
        this.$forceUpdate()
      }
      if(item.type == 3) {
        this.$router.push({
          path: '/StatementTwo', //跳转的路径
          query: {
            id:item.id
          },
        });
        this.$forceUpdate()
      }
      
      $core.setLocal("value",'报表');
    },
    goTo(url) {
      this.$router.push({
        //核心语句
        path: url, //跳转的路径
        query: {
          id:item.id
        },
      });
    },
    getDataList() {//获取消息列表
      $core.request('front/analyse/list', res => {
        if(res.status == 200) {
          this.BASE_URL = $core.BASE_URL
          this.analyseList = res.data
        }
      },{       
      });
    },
    getRecentlyUsed() {//获取消息列表
      $core.request('front/analyse/recent_report', res => {
        // console.log(res)
        if(res.status == 200) {
          this.BASE_URL = $core.BASE_URL
          this.recentlyUsed = res.data
        }
      },{
      },"GET");
    },
    CalcuMD5(pwd) {
      let num = String(Math.floor(Math.random() * (10000 - 1)) + 1)
      pwd = md5(num+pwd);
      return pwd;
    },
    getUserActive(name,event) {
      $core.request('growdata/add', res => {
        // console.log(res)
      },{
        'id':'',//消息id
        "page_id":$core.getLocal('HomeId'),//页面id
        'name':encodeURI(name),//事件类型名称
        'event':encodeURI(event),//事件
        'type':2,//消息还是列表
      });
    },
  },
  mounted() {
    this.getDataList()
    this.getRecentlyUsed()
    this.barBottom = window.document.getElementById("tabsBox").children[0].clientHeight;
  }
};
</script>

<style lang="less" scoped>
  .about {
    overflow-y: auto;
    background: #f2f2f2;
    height: 100%;
    // padding-bottom: 70px;
    .content {
      background: white;
      margin-bottom: 10px;
      // padding: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 16px;
      padding-bottom: 0;
      .contentTitle {
        margin-bottom: 24px;
      }
    }
    ul {
      padding-left: 0.5%;
      // height: 200px;
      // background: red;
      li {
        // float: left;
        display: inline-block;
        width: 33%;
        text-align: center;
        margin-bottom: 24px;
        font-size: 14px;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    
  }
</style>